.Unsubscribe {
  padding: 60px 0;
}

.Unsubscribe .logo {
  width: 160px;
  margin-bottom: 40px;
}

@media all and (min-width: 480px) {
  .Unsubscribe form {
    margin: 0 auto;
    max-width: 320px;
  }
}
