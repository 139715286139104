.Respond {
  padding: 60px 0;
}

.Respond .logo {
  width: 160px;
  margin-bottom: 40px;
}

.Respond textarea:focus {
  border-color: #888 !important;
}

@media all and (min-width: 480px) {
  .Respond .box,
  .Respond .thanks,
  .Respond form {
    margin: 0 auto;
    max-width: 320px;
  }
}
