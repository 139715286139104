.Templates {
  margin-top: 3.5rem;
}

.Templates .compose-template {
  margin-bottom: 2.5rem;
}

.Templates td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}

.Templates .button.action {
  margin: 0;
  height: 24px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 24px;
}
