.Settings {
  margin-top: 3.5rem;
}

.Settings .logo-box {
  height: 118px;
  border: 1px solid #D1D1D1;
}

.Settings .logo-box:hover {
  border: 1px solid #888;
  cursor: pointer;
}

.Settings .logo-box-input {
  visibility: hidden;
  height: 0;
  margin: 0;
}
