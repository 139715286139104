.Landing {
  margin-top: 6rem;
}

.Landing .logo {
  margin-bottom: 4rem;
}

.Landing .hero {
  margin-top: 2.5rem;
}

.Landing .start {
  margin-right: 10px;
}

.Landing .features {
  margin-top: 6rem;
  margin-bottom: 6rem;
  text-align: center;
}

.Landing .features h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.Landing .heading p {
  font-size: 18px;
  margin-bottom: 1rem;
}

.Landing.what-is-nps {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.Landing.what-is-nps ul,
.Landing.what-is-nps p {
  margin-bottom: 1rem;
  list-style: none;
}

.Landing.what-is-nps a {
  margin-top: 2rem;
}
