.App.container {
  max-width: 800px;
  padding-bottom: 100px;
}

.App .navbar {
  display: block;
  width: 100%;
  height: 8rem;
  border-bottom: 1px solid #eee;
}

.App .navbar .navbar-list {
  list-style: none;
  margin-bottom: 0;
  float: right;
}

.App .navbar .navbar-list .navbar-item {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}

.App .navbar .logo {
  display: block;
  margin-top: 2rem;
  height: 4rem;
  width: auto;
  float: left;
}

.App .navbar .tip:after {
  content: "";
  position: absolute;
  background: #FF3357;
  width: 10px;
  z-index: -1;
  height: 10px;
  left: 70px;
  top: -5px;
  transform: rotate(45deg);
}

.App .navbar .tip {
  position: absolute;
  background: #FF3357;
  width: 150px;
  left: calc(50% - 57.5px);
  top: 60px;
  font-weight: 600;
  color: white;
  text-align: center;
}

.App .navbar .navbar-list .navbar-item .navbar-link {
  font-weight: 600;
  margin-left: 35px;
  text-decoration: none;
  line-height: 8rem;
  color: #222;
}

.App .modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
}

.App .modal-container .modal {
  background: white;
  border: 1px solid #bbb;
  margin: 60px auto;
  width: 400px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}

