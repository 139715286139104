.Contacts {
  margin-top: 3.5rem;
}

.Contacts .contacts-table button.delete {
  margin: 0;
  height: 24px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 24px;
}

.Contacts input[type="file"] {
  display: none;
}

.Contacts td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}
