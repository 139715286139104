.Login {
  padding: 60px 0;
}

@media all and (min-width: 480px) {
  .Login .login-info,
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Login .logo {
  width: 116px;
  margin-bottom: 40px;
}
